import React from 'react';
import '../styles/AboutUs.css';
import aboutUsImage from '../assets/images/AboutUs.webp'; // Adjust the path according to your structure
import ParthVerma from '../assets/images/ParthVerma.png'; // Image for team member 1
import KrishnaUpman from '../assets/images/KrishnaUpman.png'; // Image for team member 2
import PawaneshKumar from '../assets/images/PawaneshKumar.png'; // Image for team member 3

function AboutUs() {
  const coreValues = [
    "Integrity",
    "Innovation",
    "Accountability",
    "Excellence",
    "Sustainability"
  ];

  return (
    <div className="aboutus-page">
      <section className="content-section">
        <div className="image-container">
          <img src={aboutUsImage} alt="About Us" />
        </div>
        <div className="content">
          <h3>About Us</h3>
          <h1>We store CO2 in Ocean</h1>
          <p>
          Impact is measured in 2 ways, first in Carbon removal, how much net CO2 is being removed from air in Tons, second in Energy storage, what capacity of storage and for how long, basically for Renewables we need long duration or time between 12 to 20 hours. With our CarbonBank, if the plant capacity is in MegaWattHour or GwH, any, with every charging discharging cycle, we will capture a certain volume of CO2 and if we consider the long life of the plant, it would have captured more CO2 than its storage capacity.
          </p>
        </div>
      </section>

      <section className="values-section">
        <h2>Core Values</h2>
        <div className="values-grid">
          {coreValues.map((value, index) => (
            <div key={index} className="value-item">{value}</div>
          ))}
        </div>
      </section>

      <section className="team-section">
        <h2>Meet Our Team</h2>
        <div className="team-grid">
          <div className="team-member">
            <img src={ParthVerma} alt="Parth Verma" />
            <h3>Parth Verma</h3>
            <p>CEO & Founder</p>
          </div>
          <div className="team-member">
            <img src={PawaneshKumar} alt="Pawanesh Kumar" />
            <h3>Pawanesh Kumar</h3>
            <p> COO & Technical Head</p>
          </div>
          <div className="team-member">
            <img src={KrishnaUpman} alt="Krishna Kant Upman" />
            <h3>Krishna Kant Upman</h3>
            <p>CTO & Digital Head</p>
          </div>
        </div>
      </section>

      <footer className="about-footer">
        <p>Want to know ? <a href="/contact">Contact Us</a></p>
      </footer>
    </div>
  );
}

export default AboutUs;
