import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className="header">
      <div className="logo">Bakz4ever</div>

      {/* Hamburger Icon */}
      {!isMenuOpen && (
        <div className="hamburger-menu" onClick={toggleMenu}>
          &#9776; {/* Hamburger Icon */}
        </div>
      )}

      {/* Mobile Menu */}
      <ul className={`nav-links ${isMenuOpen ? 'mobile-menu active' : ''}`}>
        <li><Link to="/" onClick={closeMenu}>Home</Link></li>
        <li><Link to="/about" onClick={closeMenu}>About Us</Link></li>
        <li><Link to="/contact" onClick={closeMenu}>Contact Us</Link></li>
        <li><Link to="/faq" onClick={closeMenu}>FAQ</Link></li>
        <li><Link to="/mission" onClick={closeMenu}>Mission</Link></li>
        <li><Link to="/what-we-do" onClick={closeMenu}>  </Link></li>
      </ul>

      {/* Close Icon (Only when menu is open) */}
      {isMenuOpen && (
        <div className="close-menu" onClick={closeMenu}>
          &times; {/* Close Icon */}
        </div>
      )}
    </header>
  );
}

export default Header;
