import React, { useEffect } from 'react';
import '../styles/Mission.css';

// Import dummy image for now
import OceanStorage from '../assets/images/OceanStorage.png';

function Mission() {
  useEffect(() => {
    const timelineEvents = document.querySelectorAll('.timeline-event');
    const observerOptions = {
      threshold: 0.2,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in');
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    timelineEvents.forEach((event) => {
      observer.observe(event);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="mission-page">
      <div className="hero-section">
        <div className="hero-image">
          <img src={OceanStorage} alt="Mission" />
        </div>
        <div className="hero-content">
          <h1>Our Mission: Driving Sustainability for the Future</h1>
          <h2>2030/2050</h2>
          <p>We will cross the 1.5 degree scenario before 2030 SDG targets or IPCC’s 2 degree before 2050 as the world has already warmed by about 1.3C. Carbon Bank is 2in1 technology for sustainable Direct Air Carbon Capture with long duration Energy Storage to support and stabilise 100% Renewable grid.</p>
        </div>
      </div>

      {/* Mission Points Section */}
      <div className="mission-points">
        <div className="mission-point">
          <div className="icon">🌍</div>
          <h3>Sustainability</h3>
          <p>Building a cleaner, greener future for generations to come.</p>
        </div>
        <div className="mission-point">
          <div className="icon">⚡</div>
          <h3>Renewable Energy</h3>
          <p>Harnessing natural resources to power the world.</p>
        </div>
        <div className="mission-point">
          <div className="icon">🌱</div>
          <h3>Environmental Innovation</h3>
          <p>Innovating new solutions for a sustainable world.</p>
        </div>
      </div>

      {/* Timeline Section */}
      <div className="mission-timeline">
        <div className="timeline-line"></div>
        <div className="timeline-events">
          <div className="timeline-event">
            <div className="event-dot"></div>
            <div className="event-content">
              {/* <h4>2021</h4> */}
              <p>Launched our first sustainability initiative, focusing on reducing carbon footprints.</p>
            </div>
          </div>
          <div className="timeline-event">
            <div className="event-dot"></div>
            <div className="event-content">
              {/* <h4>2022</h4> */}
              <p>Introduced cutting-edge renewable energy solutions and partnered with global leaders.</p>
            </div>
          </div>
          <div className="timeline-event">
            <div className="event-dot"></div>
            <div className="event-content">
              {/* <h4>2025</h4> */}
              {/* <p>Expanded our impact by launching initiatives in 5 new countries.</p> */}
              <p>Initially launch our first model in India.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mission;
