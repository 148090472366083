// src/pages/Faq.js
import React, { useState } from 'react';
import '../styles/Faq.css';

function Faq() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  // FAQ data
  const faqData = [
    {
      question: "What is Direct Air Capture (DAC)?",
      answer: "DAC is a technology designed to capture carbon dioxide (CO2) directly from the atmosphere for storage or reuse."
    },
    {
      question: "What is energy storage, and why is it important?",
      answer: "Energy storage systems store energy for later use, ensuring reliability, balancing supply and demand, and enabling the use of renewable energy sources like solar and wind."
    },
    {
      question: "How does CarbonBank combine DAC and energy storage?",
      answer: "CarbonBank integrates low-cost DAC with long-duration energy storage to create a sustainable, dual-purpose system for climate action."
    },
    {
      question: "What are the benefits of using DAC over traditional carbon capture methods?",
      answer: "DAC can be deployed anywhere, targets existing atmospheric CO2, and works independently of emission sources."
    },
    {
      question: "Why are you capturing Carbon?",
      answer: "Capturing carbon reduces CO2, combats climate change, achieves net-zero emissions, and supports sustainability efforts."
    },
    {
      question: "What are the benfits of carbon capturing from the environment?",
      answer: "Carbon capturing lowers greenhouse gases, mitigates climate change, protects ecosystems, improves air quality, and enables sustainable energy solutions for a healthier planet."
    }
  ];

  return (
    <div className="faq-page">
      <div className="faq-container">
        <h1>Frequently Asked Questions</h1>
        <div className="faq-list">
          {faqData.map((faq, index) => (
            <div key={index} className="faq-item">
              <button
                className={`faq-question ${activeIndex === index ? 'active' : ''}`}
                onClick={() => toggleAccordion(index)}
              >
                <span>{faq.question}</span>
                <span className={`icon ${activeIndex === index ? 'rotate' : ''}`}>&#9660;</span>
              </button>
              {activeIndex === index && (
                <div className="faq-answer">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Faq;
