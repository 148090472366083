import React from 'react';
import '../styles/WhatWeDo.css';

function WhatWeDo() {
  return (
    <div className="whatwedo-page">
      <h1>What We Do</h1>
      <p>This is the what we do page of our website.</p>
    </div>
  );
}

export default WhatWeDo;
